.App {
  text-align: center;
  font-family: "Medium";
  color: #A4946E;
  background-color: black;
  min-height: 100vh;
}

.AppWhite {
  text-align: center;
  font-family: "Medium";
  color: #A4946E;
  background-color: white;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  margin: 70px 30px 70px 30px;
  min-height: 200px;
}

.pTop {
  margin: 0;
  padding-top: 16px;
}

.noMargin {
  margin: 0;
}

.langMenu {
  height: 45px;
}

.text {
  margin: 10px;
}
@media (prefers-reduced-motion: no-preference) {
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  min-height: calc(100vh - 90px);
}

.footer {
  height: 45px;
}

@media screen and (max-width: 500px) {
  .App-header {
    font-size: 15px;
  }
}

.App-link {
  color: #A4946E;
}

.App-link:hover {
  text-decoration: underline;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Medium";   /*Can be any text*/
  src: local("FrizQuadrataPro-Regular"),
  url("./FrizQuadrataPro-Regular.otf") format("truetype");
}
